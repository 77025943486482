<template>
  <div class="iCountUp" ref="main">
    <template v-if="isVisible">
      <span :class="{'text-white': parseProp(contrast)}">
        <ICountUp
          :delay="parsedCounterDelay"
          :endVal="counterEndValue"
          :options="counterOptions"
        />
      </span> 
      {{ counterSuffix }}
    </template>
    <p v-else><span :class="{'text-white': contrast}">{{ counterPrefix }} {{ counterEndValue }}</span> {{ counterSuffix }}</p>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
export default {
  name: "VCounter",
  components: {
    ICountUp,
  },
  props: {
    contrast: {
      default: false
    },
    counterValue: {
      type: [Number, String],
      default: ''
    },
    counterDelay: {
      type: [Number, String],
      default: 800
    },
    counterSuffix: {
      type: String,
      default: ''
    },
    counterPrefix: {
      type: String,
      default: ''
    },
    counterSeparator: {
      type: String,
      default: '.'
    },
    counterDecimalPlaces: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    counterEndValue() {
      const value = this.counterValue.replace(',', '.');
      return parseFloat(value);
    },
    parsedCounterDelay() {
      return parseInt(this.counterDelay);
    },
    counterOptions() {
      const separator = this.counterSeparator;
      const decimal = separator === '.' ? ',' : '.';
      const decimalPlaces = parseInt(this.counterDecimalPlaces);

      return {
        useEasing: true,
        useGrouping: true,
        separator,
        decimal,
        decimalPlaces,
        prefix: `${this.counterPrefix} `
      }
    }
  },
  methods: {
    parseProp(prop){
      return JSON.parse(prop);
    },
    initAnimation() {
      const { main } = this.$refs;

      if (main) {
        const parentAnimate = main.closest('.aos-init');

        if (parentAnimate) {
          setTimeout(() => {
            this.isVisible = parentAnimate.classList.contains('aos-animate') && !!this.counterEndValue;
          }, 200);
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', () => this.initAnimation());
    setTimeout(() => {
      this.initAnimation();
    }, 50);
  },
};
</script>