<template>
  <div class="accordion-item">
    <h4 class="accordion-header" :id="itemId">
      <button
        class="accordion-button collapsed"
        type="button"
        aria-expanded="false"
        ref="button"
        @click="handlerClicked"
        :id="`btn-${uid}-${itemId}-accordion`"
      >
        {{ itemTitle }}
      </button>
    </h4>
    <div
      class="accordion-collapse collapse"
      :id="`collapse-${itemId}`"
      :aria-labelledby="itemId"
      :aria-controls="`btn-${uid}-${itemId}-accordion`"
      ref="content"
      aria-expanded="false"
    >
      <div class="accordion-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"AccordionContent",
  props: {
    itemTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    itemId() {
      return `accordion-item-${this.uid}`;
    }
  },
  methods: {
    handlerClicked() {
      this.isOpen = !this.isOpen;

      const { content, button } = this.$refs;
      button.setAttribute('aria-expanded', this.isOpen);
      button.classList.toggle('collapsed');

      this.calculateMaxHeight();
      content.classList.toggle('show');
      content.setAttribute('aria-expanded', this.isOpen);
    },
    calculateMaxHeight() {
      const { content } = this.$refs;
      const maxHeight__ = this.isOpen ? content.scrollHeight : 0;
      content.style.maxHeight = `${maxHeight__}px`;
    }
  },
  mounted () {
    window.addEventListener('resize', this.calculateMaxHeight);
    this.isOpen = this.$refs.content.classList.contains('show');
  },
};
</script>