<template>
  <div class="bg-geb-dark-800 p-5 md:py-20 md:px-10 rounded-1.5xl text-white">
    <div class="flex flex-col gap-8 md:gap-15">
      <nav>
        <ul class="flex flex-wrap -mt-4" role="tablist">
          <li
            v-for="(item, index) in list"
            :key="`tab-${index}-${uid}`"
            @click="setCurrent(index)"
            role="tab"
            :id="`tabs-${ item.id }-tab`"
            :aria-roledescription="item.tab_name"
            :aria-controls="`tabs-${ item.id }`"
            :aria-selected="index == 0"
            :class="{ 'border-geb-green-400': current === index }"
            class="cursor-pointer transition duration-500 text-base md:text-xl lg:text-3xl-2alt border-b-2 border-black font-semibold p-4 lg:px-8 lg:py-6 flex-grow text-center"
          >
            {{ item.tab_name }}
          </li>
        </ul>
      </nav>
      <div :id="`panels-${this.uid}`" class="relative">
        <div
          v-for="(item, index) in list"
          :key="`content-${index}-${uid}`"
          :class="
            current === index
              ? 'opacity-100 static'
              : 'opacity-0 absolute pointer-events-none invisible'
          "
          :id="`tabs-${ item.id }`"
          role="tabpanel"
          :aria-controls="`tabs-${ item.id }-tab`"
          :aria-labelledby="`tabs-${ item.id }-tab`"
          class="grid grid-cols-1 lg:grid-cols-2 gap-10 w-full transition duration-500 top-0"
        >
          <div class="flex justify-center lg:order-1">
            <figure v-if="item.image" class="rounded-xl overflow-hidden">
              <img :src="item.image" :alt="item.tab_name" />
            </figure>
          </div>
          <div class="flex flex-col items-start gap-10">
            <div class="relative" v-html="item.tab_description"></div>
            <div
              v-if="item.indicators.length"
              class="text-geb-aquamarine grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              <div v-for="(indicator, i) in item.indicators">
                <p
                  v-if="indicator.indicator_value"
                  class="text-3xl-2alt font-semibold"
                >
                  {{ indicator.indicator_value }}
                </p>
                <p v-if="indicator.indicator_name" class="text-sm uppercase">
                  {{ indicator.indicator_name }}
                </p>
              </div>
            </div>
            <a
              v-if="item.tab_cta && item.tab_cta.url"
              :href="item.tab_cta.url"
              :target="item.tab_cta.isExternal ? '_blank' : '_self'"
              class="animate-button btn-tgi"
            >
              {{ item.cta_label }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VTabsContent",
  props: {
    list: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    setCurrent(index) {
      this.current = index;
    },
  },
};
</script>
