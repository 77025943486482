<template>
  <div class="block-richtext">
    <v-tabs>
      <tab-content v-for="(item, index) in featuredContent" :key="`tab-content-${index}-{_uid}`"
        :title="item.tab_title">
        <div class="flex flex-col gap-5">
          <div class="overflow-x-auto" v-for="({ subtable_title, ...table }, it) in item.content"
            :key="`tab-table-${it}-${index}-${_uid}}]`">
            <table border="1" style="width: 100%; direction: rtl;">
              <thead>
                <tr>
                  <th scope="col">{{ lastKeys(table) }}</th>
                  <th scope="col">{{lang == 'en' ? '1Q' : '1T'}}</th>
                  <th scope="col">{{lang == 'en' ? '2Q' : '2T'}}</th>
                  <th scope="col">{{lang == 'en' ? '3Q' : '3T'}}</th>
                  <th scope="col">{{lang == 'en' ? '4Q' : '4T'}}</th>
                  <th scope="col">{{ subtable_title }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(key, index) in getKeys(table)" :key="`tr-${it}-${ir}-${_uid}`">
                  <td v-if="index === 0" colspan="1" :rowspan="getKeys(table).length - 1">
                    <a tabindex="2" target="_blank" v-if="table[lastKeys(table)] && mapFiles(table[lastKeys(table)]).file"
                      :href="mapFiles(table[lastKeys(table)]).file.url.url_path"
                      :title="mapFiles(table[lastKeys(table)]).file.url.title"
                      :aria-label="mapFiles(table[lastKeys(table)]).file.url.title"
                      class="block gap-1.25 md:gap-3 cursor-pointer text-center">
                      <img v-if="mapFiles(table[lastKeys(table)]).image" class="mx-auto max-w-xxs md:max-w-xs"
                        :src="mapFiles(table[lastKeys(table)]).image.url.url_path"
                        :alt="mapFiles(table[lastKeys(table)]).image.url.title">
                      <span>{{ mapFiles(table[lastKeys(table)]).file.url.title }}</span>
                    </a>
                    <template v-else>
                      <img v-if="mapFiles(table[lastKeys(table)]).image" class="mx-auto max-w-xxs md:max-w-xs"
                        :src="mapFiles(table[lastKeys(table)]).image.url.url_path"
                        :alt="mapFiles(table[lastKeys(table)]).image.url.title">
                    </template>
                  </td>
                  <template v-if="key !== lastKeys(table)"">
                    <td>
                      <a tabindex="1" target="_blank" v-if="table[key] && table[key][0] && table[key][0].url.url_path" :href="table[key][0].url.url_path"
                        :title="table[key][0].url.title" :aria-label="table[key][0].url.title" class="block gap-1.25 md:gap-3 cursor-pointer text-center">
                        <i v-if="table[key][0].type === 'file'"
                          class="w-full h-5 text-geb-green-secondary group-hover:text-geb-link flex justify-center"
                          v-icon:doc></i>
                        <span v-else>{{lang == "en" ? 'View' : 'Ver'}}</span>
                      </a>
                    </td>
                    <td>
                      <a tabindex="1" target="_blank" v-if="table[key] && table[key][1] && table[key][1].url.url_path" :href="table[key][1].url.url_path"
                        :title="table[key][1].url.title" :aria-label="table[key][1].url.title" class="block gap-1.25 md:gap-3 cursor-pointer text-center">
                        <i v-if="table[key][1].type === 'file'"
                          class="w-full h-5 text-geb-green-secondary group-hover:text-geb-link flex justify-center"
                          v-icon:doc></i>
                        <span v-else>{{lang == "en" ? 'View' : 'Ver'}}</span>
                      </a>
                    </td>
                    <td>
                      <a tabindex="1" target="_blank" v-if="table[key] && table[key][2] && table[key][2].url.url_path" :href="table[key][2].url.url_path"
                        :title="table[key][2].url.title" :aria-label="table[key][2].url.title" class="block gap-1.25 md:gap-3 cursor-pointer text-center">
                        <i v-if="table[key][2].type === 'file'"
                          class="w-full h-5 text-geb-green-secondary group-hover:text-geb-link flex justify-center"
                          v-icon:doc></i>
                        <span v-else>{{lang == "en" ? 'View' : 'Ver'}}</span>
                      </a>
                    </td>
                    <td>
                      <a tabindex="1" target="_blank" v-if="table[key] && table[key][3] && table[key][3].url.url_path" :href="table[key][3].url.url_path"
                    :title="table[key][3].url.title" :aria-label="table[key][3].url.title" class="block gap-1.25 md:gap-3 cursor-pointer text-center">
                    <i v-if="table[key][3].type === 'file'"
                      class="w-full h-5 text-geb-green-secondary group-hover:text-geb-link flex justify-center"
                      v-icon:doc></i>
                    <span v-else>{{lang == "en" ? 'View' : 'Ver'}}</span>
                    </a>
                    </td>
                    <td class="text-left">{{ key }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </tab-content>
    </v-tabs>
  </div>
</template>

<script>
import VTabs from "../CustomTags/Tabs/VTabs.vue"
import TabContent from "../CustomTags/Tabs/TabContent.vue"
export default {
  name: "VReportTable",
  props: {
    featuredContent: {
      type: Array,
      require: true
    },
    lang: {
      type: String,
      default: "es"
    }
  },
  data() {
    return {

    }
  },
  methods: {
    getKeys(table) {
      return Object.keys(table);
    },
    lastKeys(table) {
      const keys = Object.keys(table)
      return keys[keys.length - 1];
    },
    mapFiles(arr) {

      let file = null;
      let image = null;
      arr.forEach(item => {
        if (item.type === 'file') {
          file = item
        }
        if (item.type === 'image') {
          image = item
        }
      })

      return {
        file,
        image
      }
    }
  },
  components: {
    VTabs,
    TabContent
  }
}
</script>