import header from './header.js';
import search from './search.js';

const storeData = {
  modules: {
    header,
    search
  }
};

export let store = {};
const createStore = Vuex => {
  store = new Vuex.Store(storeData);
  return store;
};

export default createStore;
