<template>
	<span v-if="stats">
		GEB: {{ legibleStatistics.closeValue }}
		<span class="text-geb-bahia"> {{ legibleStatistics.increaseInPercentage }}</span>
	</span>
</template>

<script>
	import Axios from 'axios';
	export default {
    	name: "BvcStats",
		props: {
			endpoint: {
				type: [String, Object],
				default: ''
			}
		},
		data() {
			return {
				stats: null
			};
		},
		mounted() {
			if (this.endpoint) {
				if (typeof this.endpoint == 'string') {
					Axios.get(this.endpoint)
					.then(res => {
						this.stats = res.data.hasOwnProperty('GEB') ? res.data : null;
						})
					.catch(err => {
							this.stats = null;
						});
				} else if (typeof this.endpoint == 'object') {
					this.stats = this.endpoint.hasOwnProperty('GEB') ? this.endpoint : null;
				}
			}
		},
		computed: {
			legibleStatistics() {
				let variation = this.stats.GEB.Variation / 100;
				
				return {
                    closeValue: this.stats.GEB.closeValue.toLocaleString('es-CO', {style: "currency", currency: "COP"}).slice(0,-3),
                    increaseInPercentage: (variation >= 0 ? '+' : '') + variation.toLocaleString('es-CO', { style: "percent", maximumFractionDigits: 2 })
                }
			}
		}
	};
</script>