export default {
  state: {
    isSearching: false,
    currentSearchValue: ''
  },
  mutations: {
    setIsSearching(state, value) {
      state.isSearching = value;
    },
    setSearchValue(state, value) {
      state.currentSearchValue = value;
    }
  },
  actions: {
    updateSearchValue({ commit }, value) {
      commit('setSearchValue', value);
    }
  }
}