<template>
  <div 
    class="flex flex-col md:flex-row md:flex-wrap pt-5 lg:pt-10.75 text-geb-dark-900 transition-opacity duration-1000" 
    :class="[ isMounted ? 'opacity-100' : 'opacity-0' ]"
  >
    <div class="date w-full text-4xxs md:text-lg leading-loose md:leading-normal mb-1.5 md:mr-7">
      <span class="opacity-40">{{ config.publishd_date }}</span>
    </div>
    <div class="w-full flex flex-col md:flex-row flex-nowrap gap-3">
      <div class="w-full md:w-auto md:mr-15 flex-grow">
        <a :href="config.download_url" class="description text-2sm md:text-xl leading-5 font-semibold hover:text-geb-dark-600">
          <span>{{ config.name }}</span>
        </a>
      </div>
      <a :href="config.download_url" class="flex">
        <div class="text-2xxs md:text-3xxs leading-6 md:leading-none tracking-2widest font-semibold whitespace-nowrap w-full flex items-center lg:justify-end gap-3.5 lg:gap-2.5 mb-0.5 cursor-pointer group">
          <i class="w-5 h-5 lg:w-5.5 lg:h-5.5 fill-none text-geb-green-secondary group-hover:text-geb-dark-600 flex" v-icon:tw-download></i>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileListItem",
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  mounted () {
    setTimeout(() => this.isMounted = true, 200);
  },
}
</script>