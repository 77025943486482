const htmlTemplate = (data, style, script) => {
  const fixedData = data.split('"//www.youtube').join('"http://www.youtube');
  return `
    <html>
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        ${style}
      </head>
      <body class="overflow-x-hidden">
        <div id="app">
          ${fixedData}
        </div>
        <script type="application/javascript">
          window.addEventListener('DOMContentLoaded', () => {
            const links = document.querySelectorAll('a:not([target="_blank"])');
            for (const item of links) {
              const url = item.attributes.href.value;
              if (url !== "#") {
                item.addEventListener('click', (evt) => {
                  evt.preventDefault();
                  window.parent.postMessage({ url }, '*');
                })
              }
            }
          });
        </script>
        ${script}
      </body>
    </html>
  `;
};

export default htmlTemplate;