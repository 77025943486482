<template>
  <div class="p-5 md:p-10 lg:p-14 text-white bg-primary-a rounded-2xl overflow-hidden relative z-10">
    <span class="overlay-countdown absolute inset-0 z-0 opacity-30"></span>
    <div class="relative z-10">
      <div v-if="title" class="mb-5 md:mb-7">
        <h2 class="md:text-3xl-alt lg:text-4.5xl text-center font-semibold">
          {{ title }}
        </h2>
        <hr class="mt-4 md:mt-7 border-t border-black opacity-30">
        <hr class="border-t border-white opacity-30">
      </div>
      <div class="flex text-2xl-alt md:text-5xl-alt lg:text-7xl-alt">
        <template v-for="(item, index) in counts">
          {{ index ? ":" : "" }}
          <span class="w-1 flex-grow flex flex-col gap-2 text-center " :key="`${index}-${_uid}`">
            <span class="font-bold">
              {{onSetDate(time, item.type)}}
            </span>
            <span class="text-xxs md:text-base lg:text-1.5xl-alt uppercase">{{item.title}}</span>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
      require: true,
    },
    lang: {
      type: String,
      default: "es",
    },
  },
  data() {
    return {
      end: new Date(this.date),
      today: new Date(),
      countdown: null,
      counts: [
        { title: this.lang == "en" ? "Day(s)" : "Día(s)", type: "days" },
        { title: this.lang == "en" ? "Hour(s)" : "Hora(s)", type: "hours" },
        { title: this.lang == "en" ? "Minute(s)" : "Minuto(s)", type: "minutes" },
        { title: this.lang == "en" ? "Second(s)" : "Segundo(s)", type: "seconds" },
      ],
    };
  },
  computed: {
    time() {
      return this.end - this.today;
    },
  },
  mounted() {
    this.countdown = setInterval(() => {
      this.today = new Date();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.countdown);
  },
  methods: {
    onSetDate(date, type) {
      let result = 0;
      switch (type) {
        case "days":
          result = Math.ceil(date / (1000 * 60 * 60 * 24));
          break;
        case "hours":
          result = Math.ceil((date / (1000 * 60 * 60)) % 24);
          break;
        case "minutes":
          result = Math.ceil((date / (1000 * 60)) % 60);
          break;
        case "seconds":
          result = Math.ceil(date / 1000) % 60;
      }
      return result.toLocaleString("es-CO", {
        minimumIntegerDigits: type == "days" ? 3 : 2,
      });
    },
  },
};
</script>
