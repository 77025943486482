<template>
	<div v-if="items" class="grid grid-rows-1 lg:grid-cols-3 gap-5.25 lg:gap-11">
		<div class="lg:col-span-1 order-last lg:order-none flex flex-col gap-1.25 text-geb-green-secondary">
			<div v-for="(item, index) in items" 
				@click="selected = index" 
				class="cursor-pointer min-h-12.5 md:min-h-15.5 flex items-center pl-6 pr-2 py-3 md:pl-7 md:py-3.75 border rounded-xl"
				:class="index == selected ? 'bg-white border-geb-green-secondary' : 'border-transparent hover:bg-white hover:bg-opacity-50 hover:border-geb-green-secondary hover:border-opacity-50'"
				:key="item.id"
				>
				<div class="flex gap-5.5 md:gap-6.75">
					<div class="w-6.5 h-6.5 md:w-8 md:h-8 flex-shrink-0" v-if="item.icon">
						<i v-icon:[item.icon] class="flex items-center w-full"></i>
					</div>
					<div class="text-2xs tracking-1.8tight leading-2tighter md:text-2sm md:tracking-wide">
						<h2>{{ item.text }}</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="lg:col-span-2">
			<div class="relative">
				<figure class="w-full has-gradient rounded-3sm md:rounded-xl">
					<img :src="items[selected].imageUrl" :alt="items[selected].imageAlt" class="w-full">
				</figure>
				<div class="absolute inset-0 max-w-full mx-auto text-white px-6.25 py-5 md:px-10.5 md:pt-7 md:pb-9.75 overflow-hidden">
					<div class="relative flex flex-col justify-end h-full text-3xxs leading-2relaxed md:text-2sm" v-html="items[selected].description"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Gallery',
		props: {
			items: {
				type: [Array],
				default: () => []
			}
		},
		data() {
			return {
				selected: 0
			};
		}
	}
</script>