<template>
  <div class="rounded-md p-4" :class="classes.backgroundColor" v-if="show">
    <div class="flex gap-3">
      <div class="flex-shrink-0" v-if="mainIcon">
        <i class="h-5 w-5 flex" :class="classes.mainIconColor" v-icon:[mainIcon] aria-hidden="true"></i>
      </div>
      <div :class="classes.textColor">
        <p class="text-sm font-medium">
          <slot></slot>
        </p>
      </div>
      <div class="ml-auto">
        <div class="-mx-1.5 -my-1.5">
          <button type="button" class="inline-flex rounded-md p-1.5" :class="classes.closeColor" v-if="closable" @click="close">
            <span class="sr-only">Dismiss</span>
            <i class="h-5 w-5" v-icon:close-x-solid aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VMessage",
  props: {
    icon: {
      type: String
    },
    closable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      show: true,
      whitelistIcon: {
        success: 'check-circle',
        error: 'close-x-circle-solid',
        warning: 'exclamation-solid',
        info: 'information-circle-solid'
      }
    };
  },
  computed: {
    mainIcon() {
      return this.whitelistIcon[this.type] !== undefined ? this.whitelistIcon[this.type] : null;
    },
    classes() {
      switch (this.type) {
        case 'success':
          return {
            backgroundColor: 'bg-green-50',
            mainIconColor: 'text-green-400',
            textColor: 'text-green-800',
            closeColor: 'bg-green-50 text-green-500 hover:bg-green-100'
          }

        case 'error':
          return {
            backgroundColor: 'bg-red-50',
            mainIconColor: 'text-red-400',
            textColor: 'text-red-800',
            closeColor: 'bg-red-50 text-red-500 hover:bg-red-100'
          }

        case 'info':
          return {
            backgroundColor: 'bg-blue-50',
            mainIconColor: 'text-blue-400',
            textColor: 'text-blue-800',
            closeColor: 'bg-blue-50 text-blue-500 hover:bg-blue-100'
          }

        case 'warning':
          return {
            backgroundColor: 'bg-yellow-50',
            mainIconColor: 'text-yellow-400',
            textColor: 'text-yellow-800',
            closeColor: 'bg-yellow-50 text-yellow-700 hover:bg-yellow-100'
          }
      
        default:
          return {
            backgroundColor: 'bg-gray-50',
            mainIconColor: 'text-gray-400',
            textColor: 'text-gray-800',
            closeColor: 'bg-gray-50 text-gray-500 hover:bg-gray-100'
          }
      }
    }
  },
  methods: {
    close() {
      this.show = false;
      setTimeout(() => {
        this.$el.remove();
        this.$emit("close");
      }, 200);
    }
  }
};
</script>