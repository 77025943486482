import directives from './directives.js'
import cookies from './cookies.js'
import filters from './filters.js'

export default {
  install: (Vue, options) => {
    for (let dir in directives) {
      if (directives.hasOwnProperty(dir)) {
        Vue.directive(dir, directives[dir])
      }
    }

    for (let func in cookies) {
      if (cookies.hasOwnProperty(func)) {
        Vue.prototype[`$${func}`] = cookies[func];
      }
    }

    for (let fil in filters) {
      if (filters.hasOwnProperty(fil)) {
        Vue.filter(fil, filters[fil])
      }
    }
  }
}

export const dispatchSearchResultGAEvent = (type, terms, hasResults) => {
  if (window.gtag) {
    window.gtag('event', 'search_event', {
      search_event_type: type,
      search_event_term: terms,
      search_event_results: hasResults
    })
  }
}

export const dispatchSearchClickGAEvent = (type, terms, position, title) => {
  if (window.gtag) {
    window.gtag('event', 'search_event_click', {
      search_event_click_type: type,
      search_event_click_term: terms,
      search_event_click_result_position: position,
      search_event_click_result_title: title
    })
  }
}
