<template>
  <a 
    href="#"
    @click.prevent="$emit('click', config.id)"
    :aria-label="config.title"
    class="block transition-colors duration-300 text-2lg leading-tight px-4 py-1.5 mb-2 rounded-lg shadow-lg whitespace-nowrap font-medium tracking-tight hover:bg-geb-green-700 hover:text-white tgi-hover-bg-menu-item transition-none"
    :class="[config.active ? 'bg-geb-green-800 tgi-bg-menu-item text-white' : 'bg-white text-geb-green-700 tgi-text-menu-item']"
  >
      {{ config.title }}
  </a>
</template>

<script>
export default {
  name: "BlockMenuItem",
  props: {
    config: {
      type: Object,
      required: true
    },
    isEnabled: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    const calculateScroll = () => {
      const header = document.getElementById('header');
      const headerHeight = !!header ? header.offsetHeight : 0;
      const blockElement = document.getElementById(this.config.id);
      const blockElementRect = blockElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      const isCurrent = blockElementRect.top >= headerHeight 
        && blockElementRect.top <= (headerHeight * 1.5)
        /* && (blockElementRect.top) < viewportHeight */;

      //console.log(this.config.id, blockElementRect.top, viewportHeight)

      if (isCurrent) this.$emit('scrollId', this.config.id);
      else if ((this.config.active && blockElementRect.bottom <= (headerHeight * 2)) || !isCurrent) this.$emit('scrollId', null);
      else this.$emit('scrollId', null);
    }

    window.addEventListener('scroll', (evt) => {
      if (this.isEnabled) calculateScroll();
    });
  }
};
</script>