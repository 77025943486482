<template>
  <div class="flex items-center text-geb-dark-800">
    <form
      @submit.prevent="onSubmit"
      ref="form"
      class="form-search flex items-center transform transition-all ease-out duration-700"
    >
      <label for="input-search" class="hidden md:flex cursor-pointer">
        <input
          v-on:keyup="debouncedOnKeyup"
          name="filters[keyword]"
          type="text"
          :placeholder="placeholderSearch"
          v-model="currentValue"
          id="input-search"
          ref="input"
          @focus="onFocus"
          :aria-label="ariaLabel"
          class="md:block text-geb-dark-900 text-2xs leading-5tighter border-0 md:border-b py-1"
          :class="{ open: currentValue || isSearching }"
        />
        <button
          type="button"
          tabindex="-1"
          class="pointer-events-none"
          v-show="!isSearching"
        >
          <i
            v-icon:search
            class="w-6 h-6 lg:h-5 lg:w-5 text-geb-dark-700 md:text-current cursor-pointer block"
          ></i>
        </button>
        <button type="submit" tabindex="-1" v-show="isSearching">
          <i
            v-icon:search
            class="w-6 h-6 lg:h-5 lg:w-5 text-geb-dark-700 md:text-current cursor-pointer block"
          ></i>
        </button>
      </label>
      <button
        type="button"
        v-if="isSearching"
        @click="toggleSearch"
        @blur="onBlur"
      >
        <i
          v-icon:close-x-circle
          class="fill-none w-6 h-6 lg:h-5 lg:w-5 text-geb-dark-700 md:text-current cursor-pointer block"
        ></i>
      </button>
      <input type="hidden" name="recaptcha_token" :value="recaptchaToken" />
    </form>

    <div class="flex md:hidden">
      <i
        v-if="isSearching"
        v-icon:close-x-circle
        class="w-6 h-6 cursor-pointer"
        :style="{ fill: 'transparent' }"
        @click="toggleSearch"
      ></i>
      <i
        v-else
        v-icon:search
        class="block w-6 h-6 lg:h-5 lg:w-5 text-geb-dark-700 md:text-current cursor-pointer"
        @click="toggleSearch"
      ></i>
    </div>
    <transition name="fade">
      <header-search-popup
        @close="toggleSearch"
        :url-search="urlSearch"
        :results="results"
        :is-mobile="isMobile"
        :placeholder-search="placeholderSearch"
        :show-popup="showPopup"
        :recaptcha-enabled="recaptchaEnabled"
        :recaptcha-public-key="recaptchaPublicKey"
        v-show="showPopup"
      >
        <template v-slot:main-title>
          <slot name="search-main-title"></slot>
        </template>
        <template v-slot:topic-title>
          <slot name="search-topic-title"></slot>
        </template>
      </header-search-popup>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { EventBus } from "../../EventBus.js";
import debounce from "lodash/debounce";
import { generateRecaptchaToken } from "../../utils/recaptcha.js";
import { dispatchSearchResultGAEvent } from "../../utils/index.js";

export default {
  props: {
    ariaLabel: {
      type: String,
      default: "Buscador",
    },
    placeholderSearch: {
      type: String,
      default: "Buscar",
    },
    apiUrlSearch: {
      type: String,
      default: "",
    },
    urlSearch: {
      type: String,
      default: "",
    },
    recaptchaEnabled: {
      type: Boolean,
      default: false,
    },
    recaptchaPublicKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      viewportWidth: 0,
      mediaQuery: 768,
      isLoading: false,
      results: {},
      recaptchaToken: "",
    };
  },
  computed: {
    ...mapState({
      currentSearchValue: (state) => state.search.currentSearchValue,
      isSearching: (state) => state.search.isSearching,
    }),
    currentValue: {
      get() {
        return this.currentSearchValue;
      },
      set(input) {
        return this.$store.dispatch("updateSearchValue", input);
      },
    },
    showPopup() {
      return (
        this.isSearching && (this.currentValue.length > 2 || this.isMobile)
      );
    },
    isMobile() {
      return this.viewportWidth < this.mediaQuery;
    },
  },
  methods: {
    onFocus() {
      if (!this.isSearching) {
        this.toggleSearch();
      }
    },
    onBlur() {
      if (this.isSearching) {
        this.toggleSearch();
      }
    },
    toggleSearch() {
      this.$store.dispatch("updateSearchValue", "");
      this.$store.commit("setIsSearching", !this.isSearching);
      document.getElementById("header").classList.toggle("is-searching");

      if (this.isSearching) {
        EventBus.$emit("close-menu");
        setTimeout(() => {
          this.$refs.input.focus();
        }, 200);
      }
    },
    calculateViewportWidth() {
      this.viewportWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    },
    async onKeyup() {
      if (this.isSearching && (this.currentValue.length > 2 || this.isMobile)) {
        await generateRecaptchaToken(
          this.recaptchaEnabled,
          this.recaptchaPublicKey
        ).then(async (recaptchaToken) => {
          this.recaptchaToken = recaptchaToken;
          const response = await fetch(
            `${this.apiUrlSearch}?filters[keyword]=${this.currentValue}&recaptcha_token=${recaptchaToken}`
          );
          this.results = await response.json();

          const hasResults = Boolean(
            this.results?.items && this.results?.items?.length > 0
          );
          dispatchSearchResultGAEvent("modal", this.currentValue, hasResults);
        });
      }
    },
    debouncedOnKeyup: debounce(async function () {
      await this.onKeyup();
    }, 500),
    async onSubmit() {
      await generateRecaptchaToken(
        this.recaptchaEnabled,
        this.recaptchaPublicKey
      ).then((recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;
        const searchParams = new URLSearchParams({
          "filters[keyword]": this.currentValue,
          recaptcha_token: recaptchaToken,
        });
        window.location.href = `${this.urlSearch}?${searchParams.toString()}`;
      });
    },
  },
  async mounted() {
    window.addEventListener("resize", this.calculateViewportWidth);
    this.calculateViewportWidth();
    EventBus.$on("close-search", () => {
      if (this.isSearching) this.toggleSearch();
    });

    generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(
      async (recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;
      }
    );
  },
};
</script>
