<template>
  <transition name="fade-notification" v-cloak>
    <div class="cookie-notification" v-if="showNotification && cookieName">
      <div class="xl:container flex xl:px-4">
        <div class="cookie-notification__content">
          <div class="content">
            <slot></slot>
          </div>
        </div>
        <div class="cookie-notification__actions">
          <button type="button" class="button button--cookie" @click="close">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CookiesNotification",
  props: {
    buttonText: {
      type: String,
      default: "Accept",
    },
    cookieType: {
      type: String,
      default: "geb",
    },
  },
  data() {
    return {
      showNotification: false,
      cookies: {
        tgi: "gebweb-cookie-consent-tgi",
        geb: "gebweb-cookie-consent",
      },
    };
  },
  computed: {
    //  Cookie name set as computed value so it cannot be altered by accident
    cookieName() {
      return this.cookies[this.cookieType.toLowerCase()];
    },
  },
  methods: {
    close() {
      this.showNotification = false;

      if (this.$getCookie(this.cookieName) === null) {
        const expiration = new Date();
        expiration.setFullYear(expiration.getFullYear() + 1);
        this.$setCookie(this.cookieName, "", expiration);
      }

      //  Wait for animation before removing
      setTimeout(() => {
        this.$destroy();
        this.$el.remove();
      }, 250);
    },
  },
  mounted() {
    if (this.$getCookie(this.cookieName) !== null) {
      this.close();
    } else {
      this.showNotification = true;
    }
  },
};
</script>
