import Vue from 'vue';
import Vuex from "vuex";
import axios from 'axios';
import VueAxios from 'vue-axios';
import UniqueId from 'vue-unique-id';
import AOS from 'aos';

import createStore from './store/index.js'
import UtilsPlugin from './utils/index.js';
import components from './components/index.js';
import { EventBus } from "./EventBus.js";

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'tw-elements';
import 'aos/dist/aos.css';

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(UtilsPlugin,{
  eventBus: EventBus
});
Vue.use(UniqueId);

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking-inline.css";
Vue.use(Flicking);

const initAOS = () => {
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 180, // offset (in px) from the original trigger point
    delay: 200, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });

  window.addEventListener('load', AOS.refresh);
  window.addEventListener('resize', AOS.refresh);
}

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey])
}

initAOS();

new Vue({
  el: '#app',
  store: createStore(Vuex),
  delimiters: ["${", "}"],
  mounted () {
    setTimeout(AOS.refresh, 400);
  },
});