<template>
  <div class="flex flex-col" :aria-label="title">
    <button role="button" ref="axis" class="flex flex-grow gap-6 lg:gap-7 min-h-axis md:min-h-2axis bg-white bg-opacity-90 border border-geb-green-secondary rounded-2lg md:rounded-xl text-geb-green-secondary hover:text-geb-green-900 hover:shadow-md p-7 md:pt-8 md:pl-10 cursor-pointer hyphens-auto overflow-hidden" @click="toggleAxis">
      <i class="flex-shrink-0 w-13.5 h-13.5 lg:w-16 lg:h-16 fill-none" v-if="icon" v-icon:[icon]></i>
      <p class="font-medium text-2xs leading-5 tracking-tight lg:text-base lg:leading-sm lg:pt-2 break-words">{{ title }}</p>
    </button>
    <axis-sidebar @close="toggleAxis" v-if="isOpen" :url="url" :ariaLabelledby="title"></axis-sidebar>
  </div>
</template>

<script>
export default {
  name: "VAxis",
  props: {
    title: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: '#'
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleAxis() {
      this.isOpen = !this.isOpen;
      const containers = document.querySelectorAll(".full-container");
      containers.forEach((element) => element.classList.toggle('z-auto'));
      this.$refs['axis'].focus();
    }
  }
}
</script>