<template>
  <div tabindex="-1" class="axis-sidebar fixed inset-0 z-20" :class="{ open: open }" ref="main">
    <div class="axis-sidebar--backdrop" @click="close"></div>
    <div class="axis-sidebar--container w-full max-w-sm md:max-w-axis-sibebar md:w-7/12 min-h-screen h-full overflow-hidden absolute px-4 pt-8 pb-9 md:px-13.5 md:pt-20 md:pb-12.5 ml-auto bg-white">
      <div class="h-full flex flex-col gap-4.5 md:gap-0">
        <div class="flex items-center relative z-50">
          <button class="axis-sidebar--close-button p-0.75 rounded-full flex justify-center items-center bg-geb-green-secondary shadow-md hover:shadow-xl md:fixed md:top-1/2 md:transform md:-translate-x-21 md:-translate-y-1/2" @click="close" aria-label="Close Modal">
            <i v-icon:chevron-down class="text-white w-3.5 h-3.5 md:w-13 md:h-13 fill-none transform -rotate-90"></i>
          </button>
        </div> 
        <div class="flex flex-col flex-grow hyphens-auto" role="dialog" aria-modal="true" a:aria-labelledby="ariaLabelledby">
          <axis-skeleton id="news-article" v-if="isFetching || (!compiledHtml && !errorHtml)"></axis-skeleton>
          <component :is="errorHtml" v-else-if="!!errorHtml"></component>
          <div id="axis-sidebar--compiled" class="flex-grow h-full" v-else-if="!!compiledHtml">
            <axis-skeleton id="news-article" v-if="!isHtmlLoaded"></axis-skeleton>
            <iframe v-show="isHtmlLoaded" ref="iframe" class="w-full h-full" :src="compiledHtml" frameborder="0" @load="iframeLoaded"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import iframeHtmlTemplate from './AxisIframeHtmlTemplate.js'

export default {
  name: "AxisSidebar",
  props: {
    url: {
      type: String,
      default: ''
    },
    ariaLabelledby: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      compiledHtml: null,
      isFetching: false,
      isHtmlLoaded: false,
      errorHtml: null,
      errorMessage: null,
      sourceAxios: null,
      dataAOS: null,
      open: false,
      enabled: true
    }
  },
  computed: {
    errorData() {
      return `<v-message type="error">${this.errorMessage}</v-message>`;
    }
  },
  methods: {
    toggleOpenContainer(){
      if (!this.enabled) return;

      this.open = !this.open;
      this.enabled = false;

      setTimeout(() => this.enabled = true, 800);

      const dataAOS = this.open ? 'none' : this.dataAOS;
      this.$refs.main.closest('[data-aos]').setAttribute('data-aos', dataAOS);
      document.getElementsByTagName('body')[0].classList.toggle('overflow-hidden');
    },
    close() {
      if (!this.enabled) return;
      this.toggleOpenContainer();
      setTimeout(() => {
        this.$emit('close');
      }, 800);
    },
    getContent() {
      this.isFetching = true;

      this.sourceAxios = this.axios.CancelToken.source();
      this.axios.get(this.url, { cancelToken: this.sourceAxios.token })
        .then(res => {
          if ([200].includes(res.status) && typeof res.data !== 'object') {
            this.setContent(res.data);
          } else {
            this.errorMessage = 'Lo sentimos, se ha presentado un error al intentar obtener el contenido.';
            this.setContent(this.errorData);
          }
        })
        .catch(err => {
          console.log(err)
          const { response, message } = err;

          if (!!response && [404, 417].includes(response.status)) {
            this.errorMessage = 'El contenido no existe o no está disponible en este momento.';
          } else {
            switch (message) {
              case 'Network Error':
                this.errorMessage = 'Ocurrió un error de red.';
                break;
            
              default:
                this.errorMessage = 'Ocurrió un error.';
                break;
            }
          }

          this.errorHtml = Vue.compile(`<div id="axis-sidebar--compiled">${this.errorData}</div>`);
          this.isHtmlLoaded = true;
        })
        .finally(() => {
          this.sourceAxios = null;
          this.isFetching = false;
        })
    },
    setContent(data) {
      const getStyles = document.querySelectorAll('link[rel="stylesheet"]');
      const clodedStyles = [];

      for (const item of getStyles) {
        const newStyle = document.createElement('link');
        newStyle.rel = item.rel;
        newStyle.href = item.href;
        clodedStyles.push(item.outerHTML);
      }

      const getScripts = document.getElementsByTagName('script');
      const clonedScripts = [];

      for (const item of getScripts) {
        if(item.src.includes('main.js') || item.src.includes('tradingview')) {
          const newScript = document.createElement('script');
          newScript.src = item.src;
          clonedScripts.push(newScript.outerHTML);
        }
      }

      const getIframeHtmlTemplate = iframeHtmlTemplate(data, clodedStyles.join(''), clonedScripts.join(''));
      const b64EncodeUnicode = (str) => {
        return btoa(encodeURIComponent(str)
          .replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode('0x' + p1);
          })
        )
      };

      const encodedData = b64EncodeUnicode(getIframeHtmlTemplate);
      const dataEncodeURI = encodeURI(`data:text/html;base64,${encodedData}`);
      this.compiledHtml = dataEncodeURI;
    },
    iframeLoaded() {
      this.isHtmlLoaded = true;
      this.$refs['iframe'].contentWindow.focus();
    },
    onKeypress(e){
      if(e.key === "Escape")
        this.close();
    }
  },
  mounted () {
    this.dataAOS = this.$refs.main.closest('[data-aos]').getAttribute('data-aos');

    setTimeout(() => {
      this.getContent();
      this.toggleOpenContainer();
    }, 20);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.url) {
        window.location = event.data.url;
      }
    })
    window.addEventListener("keydown", this.onKeypress)
  },
  beforeDestroy() {
    if (this.sourceAxios) {
      this.sourceAxios.cancel('Cancel');
    }
    window.removeEventListener("keydown", this.onKeypress)
  }
};
</script>