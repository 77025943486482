<template>
	<div class="flicking-panel full has-background-primary">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'FlickerPanel',
		props: {
			thumbs: {
				type: String,
				default: null
			},
			caption: {
				type: String,
				default: null
			}
		}
	}
</script>