<template>
	<div class="vue-video" :style="{maxWidth: `${width}px`}">
		<video 
			ref="video"
			:style="{aspectRatio:`${width} / ${height}`}"
			@ended="onEnded"
			@canplay="onCanplay"
			:width="width"
			:height="height"
			:title="title"
			:caption="caption"
			:controls="controls"
			:poster="poster"
			:src="src"
		>
		</video>
		<span v-if="show.play" class="video-btn" @click="onPlay">
			<i v-icon:play></i>
		</span>
	</div>
</template>

<script type="application/javascript">
	export default {
		name: 'VueVideo',
		props: {
			width: {
				type: String,
				default: "300"
			},
			height: {
				type: String,
				default: "150"
			},
			poster: {
				type: String,
				default: null
			},
			title: {
				type: String,
				default: null
			},
			caption: {
				type: String,
				default: null
			},
			src: {
				type: String,
				required: true
			}
		},
		data () {
			return {
				controls: false,
				canplay: false,
				show: {
					play: false,
					poster: true
				}
			}
		},
		methods: {
			onPlay(){
				const video = this.$refs['video'];
				
				video.play();

				this.show.poster = false;
				this.show.play = false;
				this.controls = true;
			},
			onCanplay(){
				if(!this.canplay)
					this.show.play = true;
				this.canplay = true;
			},
			onEnded(){
				this.show.play = true;
				this.controls = false;
			}
		}
	}
</script>