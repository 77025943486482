<template>
  <div class="py-4 w-full mx-auto">
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-6 py-1">
        <div class="w-48 h-4 bg-gray-200 rounded"></div>
        <div class="space-y-3">
          <div class="flex w-full gap-4">
            <div class="w-full h-4 bg-gray-200 rounded"></div>
            <div class="w-10 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>

        <hr class="border-0 my-8" />

        <div class="w-48 h-4 bg-gray-200 rounded"></div>
        <div class="space-y-3">
          <div class="flex w-full gap-4">
            <div class="w-full h-4 bg-gray-200 rounded"></div>
            <div class="w-10 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AxisSkeleton"
  }
</script>