<template>
  <div
    class="tab-pane fade"
    role="tabpanel"
    :aria-labelledby="`tabs-${tabId}-tab`"
    :aria-controls="`tabs-${tabId}-tab`"
    :id="`tabs-${tabId}`"
    :data-title="title"
    :data-tab-id="tabId"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "TabContent",
    props: {
      title: {
        type: String,
        required: true
      }
    },
    computed: {
      tabId() {
        const title_id = this.title.toLowerCase().normalize("NFD").replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '-');
        return `${title_id}_${this.uid}`;
      }
    },
  }
</script>