export async function generateRecaptchaToken(recaptchaEnabled, recaptchaPublicKey) {
  if (!recaptchaEnabled) {
    throw new Error('recaptcha is not enabled!')
  }

  if (!recaptchaPublicKey) {
    throw new Error('recaptchaPublicKey is missing!')
  }

  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(recaptchaPublicKey, { action: 'submit' })
        .then(token => {
          console.info('reCAPTCHA token generated successfully')
          resolve(token)
        })
        .catch(err => {
          console.error('Failed to generate reCAPTCHA token', err)
          reject(err)
        })
    })
  })
}
