<template>
  <ul
    v-if="showMenu && blocks.length"
    class="fixed right-5 inset-y-2/4 transform -translate-y-1/2 hidden md:block z-10"
  >
    <li v-for="block in blocks" :key="block.id" class="flex justify-end">
      <block-menu-item
        @click="handleClick"
        @scrollId="setActive"
        :config="block"
        :is-enabled="isEnabled"
      />
    </li>
    <li class="flex justify-end">
      <i
        @click="toggleMenu"
        role="button"
        aria-label="close"
        class="block h-6 w-6 text-geb-green-secondary tgi-close-menu-block cursor-pointer hover:text-opacity-70 fill-none"
        v-icon:close-x-circle
      ></i>
    </li>
  </ul>
  <div
    class="fixed right-5 bottom-5 z-10"
    v-else-if="!showMenu && blocks.length"
  >
    <div class="flex justify-end">
      <div
        class="flex justify-center items-center rounded-full bg-white text-geb-green-secondary tgi-close-menu-block shadow-2md w-10 h-10 cursor-pointer group"
      >
        <i
          class="flex items-center h-5 w-5 group-hover:opacity-70"
          aria-hidden="true"
          @click="toggleMenu"
          v-icon:menu
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import BlockMenuItem from "./BlockMenuItem.vue";

export default {
  components: { BlockMenuItem },
  data() {
    return {
      blocks: [],
      showMenu: true,
      isEnabled: true,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleClick(id) {
      this.isEnabled = false;
      const el = document.getElementById(id);
      if (!el) {
        window.location.hash = "";
        setTimeout(() => (this.isEnabled = true), 1000);
        return;
      }

      this.setActive(id);
      const header = document.getElementById("header");
      const scrollTo = !!header
        ? el.offsetTop - header.offsetHeight / 4
        : el.offsetTop;
      window.scrollTo(0, scrollTo);
      setTimeout(() => (this.isEnabled = true), 1000);
    },
    setActive(id) {
      this.blocks.forEach((el) => (el.active = false));
      if (!id) return;
      // this.blocks.find(el => el.id === id).active = true;
      if (window.location.hash.substring(1) !== id)
        history.replaceState(null, null, `#${id}`);
    },
    setScroll() {
      if (window.location.hash) {
        const hash = window.location.hash.substring(1);
        this.handleClick(hash);
      }
    },
  },
  mounted() {
    let blockNodeList = document.querySelectorAll("[data-menu]");
    this.blocks = Array.from(blockNodeList)
      .filter((el) => el.dataset.menu.length && !!el.id)
      .map((el) => ({ id: el.id, title: el.dataset.menu, active: false }));

    this.blocks = this.blocks.slice(0, 7);

    setTimeout(this.setScroll, 200);
    window.addEventListener("popstate", () => {
      if (window.location.hash) {
        const hash = window.location.hash.substring(1);
        const block = this.blocks.find((el) => el.id === hash);
        console.log(block.active);

        if (block && !block.active) {
          this.setScroll(hash);
        } else if (!block) {
          window.location.hash = "";
        }
      }
    });
  },
};
</script>
