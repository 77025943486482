<template>
  <transition name="fade">
    <div v-if="isFetching">
      <file-list-skeleton />
    </div>
    <div v-else-if="results.length > 0" class="flex flex-col">
      <div class="grid gap-x-6 md:gap-x-20 gap-y-4" :class="[ columns === 2 ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2' : 'grid-cols-1']">
        <file-list-item v-for="item in resultsToShow" :key="item.id" :config="item" />
      </div>
      <div v-if="results > resultsToShow" class="flex justify-end mt-8">
        <slot name="view-more-button" :getResults="getResults"></slot>
      </div>
    </div>
    <div v-else class="flex max-w-xs md:max-w-sm">
      <v-message type="error">{{ errorMessage }}</v-message>
    </div>
  </transition>
</template>

<script>
export default {
  name: "FileList",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    showLimit: {
      type: Number,
      default: 16
    },
    columns: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      errorMessage: null,
      isFetching: true,
      results: [],
      resultsToShow: []
    }
  },
  methods: {
    getResults() {
      this.resultsToShow = JSON.parse(JSON.stringify(this.results));
    }
  },
  mounted () {
    this.axios.get(this.endpoint)
      .then(response => {
        this.results = response.data;
        this.getResults();
        this.resultsToShow = this.resultsToShow.slice(0, this.showLimit);
      })
      .catch(err => {
        console.log(err)
        this.errorMessage = "Ocurrió un error al obtener los datos.";
      })
      .finally(() => {
        this.isFetching = false;
      });
  },
};
</script>

