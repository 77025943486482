<template>
  <div class="flicker-slide">
    <flicking ref="main" :options="optMain" :plugins="plugins" class="slide-main">
    	<slot></slot>
    	<template v-if="dots">
      	<div slot="viewport" class="flicking-pagination"></div>
    	</template>
      <template v-if="arrows">
      	<span slot="viewport" class="flicking-arrows flicking-arrow-prev">
      		<i v-icon:chevron-left></i>
      	</span>
  			<span slot="viewport" class="flicking-arrows flicking-arrow-next">
      		<i v-icon:chevron-right></i>
  			</span>
      </template>
    </flicking>
    <flicking v-if="hasCaptions" ref="caption" :options="optCaption" class="slide-caption">
      <template v-for="(item, index) in $slots.default">
      	<flicker-panel v-if="item.tag" :key="`caption-${uid}-${index}`">
					<figcaption v-if="item.componentOptions.propsData.caption">
						{{item.componentOptions.propsData.caption}}
					</figcaption>
				</flicker-panel>
    	</template>
    </flicking>
    <flicking v-if="thumbnails" ref="thumbs" :options="optThumbs" class="slide-thumbs">
      <template v-for="(item, index) in $slots.default">
      	<flicker-panel v-if="item.tag" :key="`thumbs-${uid}-${index}`">
      		<figure class="slide-thumbs-items">
						<img draggable="false" class="panel-image" :src="item.componentOptions.propsData.thumbs" />
      		</figure>
				</flicker-panel>
    	</template>
    </flicking>
  </div>
</template>
<script>
import { Sync, Pagination, Arrow } from "@egjs/flicking-plugins";

export default {
	props: {
		thumbnails: {
			type: Boolean,
			default: false
		},
		dots: {
			type: Boolean,
			default: false
		},
		arrows: {
			type: Boolean,
			default: false
		}
	},
  data() {
    return {
      plugins: [],
      optThumbs: {
      	bound: true,
      	bounce: 30,
      	moveType: 'freeScroll'
      },
      optCaption: {
      	panelsPerView: 1,
      	bounce: 30,
      	horizontal: true,
      	adaptive: true
      }
    }
  },
  computed: {
  	optMain(){
  		return {
  			panelsPerView: 1,
      	bounce: 30
      }
  	},
  	hasCaptions(){
  		let res = [];
  		for(let item of this.$slots.default)
  			if(item.componentOptions)
  				res.push(Boolean(item.componentOptions.propsData.caption));

  		return res.includes(true);
  	}
  },

  mounted() {
  	
  	if(this.hasCaptions){
	  	this.plugins.push(new Sync({
	      type: "camera",
	      synchronizedFlickingOptions: [
	        {
	          flicking: this.$refs.main,
	          isClickable: false
	        },
	        {
	          flicking: this.$refs.caption,
	          isClickable: false
	        }
	      ]
	    }));
  	}
  	if(this.thumbnails){
  		let synchronizedFlickingOptions = [];
  		synchronizedFlickingOptions = [
        {
          flicking: this.$refs.main,
          isSlidable: true
        },
        {
          flicking: this.$refs.thumbs,
          isClickable: true,
          activeClass: "active"
        }
      ]

      if(this.hasCaptions)
      	synchronizedFlickingOptions.push( {
       	 	flicking: this.$refs.caption,
       	 	isSlidable: true
      	});

	    this.plugins.push(new Sync({
	      type: "index",
	      synchronizedFlickingOptions
	    }));
  	}
  	
  	if(this.dots)
  		this.plugins.push(new Pagination({ type: 'bullet' })); 

  	if(this.arrows)
  		this.plugins.push(new Arrow()); 
  	
  }
}
</script>