<template>
  <article :aria-label="ariaLabel" class="w-full h-full grid grid-cols-1 md:grid-cols-5 md:gap-4" >
    <div v-if="stats" class="md:col-span-2 justify-self-center md:justify-self-start mb-14 md:mb-0 md:pl-0.75">
      <div class="w-full md:w-auto text-2xl-3alt leading-tight tracking-tightest md:text-3xl-alt md:tracking-2tight font-semibold pl-13.55 md:pl-0 md:pt-3">GEB</div>
      <div class="text-7xl md:text-6xl lg:text-7xl xl:text-7xl-2.5alt xl:leading-7xl-2.5alt tracking-3tight font-semibold mt-3 md:mt-3.25">{{ legibleStatistics.closeValue }}</div>
      <div class="text-2xl lg:text-3xl font-semibold mt-2 md:mt-2.5 flex gap-5.5 md:gap-7.5">
        <div :class="{'text-geb-orangeJaffa': legibleStatistics.increaseInUnits !== 0}">{{ legibleStatistics.increaseInUnits }}</div>
        <div :class="{'text-geb-green-secondary' : legibleStatistics.increaseInPercentage !== 0}">{{ legibleStatistics.increaseInPercentage }}</div>
      </div>
    </div>
    <div class="w-full justify-self-center" :class="[stats ? 'md:col-span-3' : 'md:col-span-5']">
      <div class="tradingview-widget-container">
        <div :id="container_id"></div>
      </div>
    </div>
  </article>
</template>

<script>
import Axios from 'axios';
export default {
  name: "Emissions",
  props: {
    endpoint: {
      type: [String, Object],
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      stats: null,
      container_id: null
    };
  },
  created () {    
    this.container_id = `tradingview_${this.uid}`;
  },
  mounted() {
    new TradingView.widget({
      "width": '100%',
      "height": '100%',
      "symbol": "BVC:GEB",
      "interval": "D",
      "timezone": "America/Bogota",
      "theme": "light",
      "style": "2",
      "locale": "es",
      "enable_publishing": false,
      "hide_top_toolbar": true,
      "hide_legend": true,
      "save_image": false,
      "container_id": this.container_id
    });
    
    if (typeof this.endpoint == 'string') {
      Axios.get(this.endpoint)
        .then(res => {
            this.stats = res.data.hasOwnProperty('GEB') ? res.data : null;
        }).catch(err => {
            this.stats = null;
        });
    } else if (typeof this.endpoint == 'object') {
      this.stats = this.endpoint.hasOwnProperty('GEB') ? this.endpoint : null;
    }
  },
  computed: {
    legibleStatistics() {
      if(this.stats == null){
        return {
          closeValue: 0,
          increaseInUnits: 0,
          increaseInPercentage: 0
        }
      }

      const diff = this.stats.GEB.closeValue - this.stats.GEB.PreviousCloseValue;
      const variation = this.stats.GEB.Variation / 100;

      const diffIsPositive = diff > 0;
      const variationIsPositive = variation > 0;

      const closeValue = this.stats.GEB.closeValue.toLocaleString('es-CO', {style: "currency", currency: "COP", maximumFractionDigits: 0});
      const increaseInUnits = diff === 0 ? 0 : `${ diffIsPositive ? '+' : '' }${diff.toLocaleString('es-CO', {style: "currency", currency: "COP", maximumFractionDigits: 0})}`;
      const increaseInPercentage = variation === 0 ? 0 : `${ variationIsPositive ? '+' : '' }${variation.toLocaleString('es-CO', { style: "percent", maximumFractionDigits: 2 })}`;

      return {
        closeValue,
        increaseInUnits,
        increaseInPercentage
      }
    }
  }
};
</script>