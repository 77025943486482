<template>
  <ul class="flex flex-col mt-0.5" v-if="megamenus.length > 0" aria-label="Menu principal">
    <li
      v-for="item in megamenus"
      :key="item.id"
      class="font-medium text-base leading-none tracking-tighter text-geb-link border-b border-geb-link border-opacity-30"
      v-collapsible
    >
      <div
        class="flex flex-grow justify-between items-center h-13.5 uppercase px-4 pt-0.5"
        :class="{ 'cursor-pointer': item.children.length > 0 }"
      >
        <a :href="item.url" :aria-label="item.name" class="hover:underline">
          {{ item.name }}
        </a>
        <i
          role="button"
          :aria-label="`Toggle - ${item.name}`"
          class="arrow flex-shrink w-6 h-6 -mt-2.5 -mr-px fill-none"
          v-icon:chevron-down
          v-if="item.children.length > 0"
        ></i>
      </div>
      <div
        v-if="item.children.length > 0"
        data-collapsible
        class="hidden bg-black bg-opacity-20"
      >
        <ul
          aria-label="Submenu - Menu principal"
          class="flex flex-col pl-7 pr-8 pt-4.5 pb-2.5 border-t border-geb-link border-opacity-30"
        >
          <li
            v-for="child in item.children"
            :key="child.id"
            class="text-2sm font-medium leading-tighter tracking-tight mb-3.5"
          >
            <a
              :href="child.link"
              :aria-label="child.title"
              class="hover:underline"
              >{{ child.title }}</a
            >
          </li>
        </ul>
        <hr
          v-if="item.featured.length > 0"
          class="mx-4 border-geb-link border-opacity-30"
        />
        <div v-if="item.featured.length > 0" class="flex flex-col">
          <a
            v-for="featured in item.featured"
            :key="featured.id"
            :href="featured.url"
            class="flex flex-wrap px-4 py-7"
            :aria-label="featured.title"
          >
            <p
              role="heading"
              class="w-full text-white text-sm tracking-tight leading-normal font-semibold mb-3"
            >
              {{ featured.title }}
            </p>

            <div class="flex gap-3.5">
              <picture class="w-33.5 flex-shrink-0">
                <img
                  :src="featured.promo_image"
                  :alt="featured.title"
                  class="rounded-2sm w-full h-22.5 pr-px pb-px object-cover object-center"
                />
              </picture>

              <div class="w-auto">
                <p
                  class="text-white text-2xxs leading-4 tracking-tight font-normal mb-2.5"
                >
                  {{
                    featured.description | parseXML | getPromoDescriptionFromXML
                  }}
                </p>
                <div
                  class="text-geb-link text-xxs-2alt tracking-tight flex items-center gap-1"
                >
                  <span
                    class="w-5 text-geb-link pr-1"
                    v-icon:arrow-right-link
                  ></span>
                  {{ microcopyFeatured }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderMegamenuMobile",
  props: {
    microcopyFeatured: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      megamenus: (state) => state.header.megamenus,
    }),
  },
};
</script>
