const filters = {
  parseXML: (xmlData) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData,"text/xml");
    return xmlDoc;
  },
  getPromoDescriptionFromXML: (xmlDoc) => {
    const text = xmlDoc.getElementsByTagName('para');
    return text.length > 0 ? text[0].innerHTML : '';
  }
}

export default filters;