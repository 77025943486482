const cookiesUtils = {
  getCookie: (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },
  setCookie: (name, value, expiration = 'session', domain = window.location.hostname) => {
    let expires = '';
    expires = `expires= ${expiration instanceof Date ? expiration.toUTCString() : expiration}`;
    document.cookie = `${name} = ${value}; ${expires}; Domain=${domain}; Path=/`;
  }
};

export default cookiesUtils;