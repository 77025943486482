<template>
  <div class="ezrichtext-field">
    <ul class="nav nav-tabs" :id="navId" role="tablist" ref="tabList">
      <tab-item-list v-for="item in tabList" :key="item.id" :text="item.title" :tabId="item.tabId" />
    </ul>

    <div class="tab-content" ref="tabContent">
      <slot></slot>
    </div> 
  </div>
</template>

<script>
  export default {
    name: "VTabs",
    data() {
      return {
        tabList: null,
        navId: null
      }
    },
    created () {
      this.navId = `nav-tabs_${this.uid}`;
    },
    mounted () {
      const tabContent = this.$refs.tabContent.querySelectorAll('.tab-pane');
      ['show', 'active'].forEach(className => tabContent[0].classList.add(className));
      
      this.tabList = Array
        .from(tabContent)
        .filter(el => el.dataset.tabId && el.dataset.title)
        .map(el => ({ tabId: el.dataset.tabId, title: el.dataset.title }));

      setTimeout(() => {
        const tabsLinks = this.$refs.tabList.querySelectorAll('.nav-link');
        tabsLinks[0].classList.add('active');
        tabsLinks[0].setAttribute('aria-selected', 'true');
      }, 200);
    }
  }
</script>