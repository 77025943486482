<template>
  <div class="flex flex-col" :class="parentClasses" ref="main">
    <slot name="form" :isSending="isSending" :onSubmit="onSubmit"></slot>

    <div class="flex flex-col" :class="messageClasses" v-if="message">
      <v-message :type="message.type" closable @close="closeMessage">
        {{ message.text }}
      </v-message>
    </div>

    <slot name="privacy-policy"></slot>
  </div>
</template>

<script>
  export default {
    name: "NewsLetter",
    props: {
      url: {
        type: String,
        default: '#'
      },
      isInvestor: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        form: null,
        isSending: false,
        message: null
      }
    },
    computed: {
      parentClasses() {
        return this.isInvestor 
          ? 'px-0'
          : 'md:w-92 lg:w-121 md:flex-shrink-0 lg:pl-0.5 px-6 md:px-0';
      },
      messageClasses() {
        return this.isInvestor 
          ? 'mt-3.5 order-3'
          : 'mb-3.5';
      }
    },
    methods: {
      closeMessage() {
        this.message = null;
      },
      onSubmit() {
        if (this.isSending) return;
        this.isSending = true;
        this.message = null;
        const formData = new FormData(this.form);

        this.axios({
            method: 'post',
            url: this.url,
            data: formData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          })
          .then(response => {
            this.message = {
              text: 'Se ha suscrito correctamente!',
              type: 'success'
            }
          })
          .catch(error => {
            console.log(error);
            this.message = {
              text: 'Ha ocurrido un error al realizar la suscripción, intente de nuevo más tarde por favor.',
              type: 'error'
            }
          })
          .finally(()=>{
            this.form.reset();
            this.isSending = false;
          });
      }
    },
    mounted () {
      this.form = this.$refs.main.querySelector('form');
      if(this.form) this.form.classList.add('form-newsletter');
    },
  }
</script>