<template>
  <section ref="main" :class="{ 'flex flex-col force-full-width': !isMedios }">
    <div class="flex flex-col pb-px full-container">
      <div class="flex justify-between lg:-ml-0.75" v-if="!isListFiles">
        <nav ref="tabs" class="-mb-px flex space-x-5 lg:space-x-12" aria-label="Tabs" role="tablist">
          <slot name="tabs" :clicked="handlerClicked"></slot>
        </nav>
        <slot name="view-more"></slot>
      </div>
      <slot name="events" :audioClicked="handlerAudioClicked" :webcastClicked="handlerWebcastClicked" :docClicked="handlerDocClicked"></slot>
    </div>

    <div class="fixed inset-0 min-h-screen overflow-hidden z-30" ref="player" v-if="isOpen">
      <div class="bg-black bg-opacity-40 w-screen h-screen absolute z-10" @click="toggleOpen"></div>

      <div class="absolute z-50 inset-0 mx-5 xl:container xl:mx-auto flex items-center justify-center" v-if="hasError">
        <div class="flex justify-center items-center max-w-xs md:max-w-sm">
          <v-message type="error">{{ errorMessage }}</v-message>
        </div>
      </div>

      <div class="absolute z-50 inset-0 mx-auto flex items-center justify-center" v-else-if="isAudio">
        <div class="flex bg-white rounded-xl justify-center items-center max-w-xs md:max-w-sm px-2 py-10 md:px-4">
          <audio controls @error="handlerError" :src="urlElement" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>

      <div class="absolute z-50 inset-0 mx-auto flex items-center justify-center md:max-w-3xl" v-else-if="isVideo">
        <video :width="width" :height="height" controls @error="handlerError" :src="urlElement" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="absolute z-50 inset-0 mx-auto flex items-center justify-center md:max-w-3xl" v-else-if="isVideoEmbed">
        <iframe :width="width" :height="height" ref="videoIframe" @load="handlerLoadVideoEmbed" @error="handlerError" :src="urlElement" title="Video player" frameborder="0" allow="picture-in-picture" allowfullscreen></iframe>
      </div>

      <div class="absolute z-50 inset-0 mx-auto flex items-center justify-center md:max-w-3xl" v-else-if="isDocument">
        <document-embed
          :url="urlElement"
          :width="width"
          :height="height"
        />
      </div>

      <div class="absolute z-50 inset-0 mx-auto flex items-center justify-center md:max-w-3xl" v-else>
        <div class="flex justify-center items-center max-w-xs md:max-w-sm">
          <v-message type="warning">El tipo de contenido no es valido.</v-message>
        </div>
      </div>

      <div class="flex absolute top-5 right-5 cursor-pointer bg-white bg-opacity-80 hover:bg-geb-gray-25 hover:bg-opacity-90 rounded-md group z-50" @click="toggleOpen">
        <i class="w-8 h-8 text-geb-dark-800 group-hover:text-geb-gray-700" v-icon:close-x></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VEvents",
  props: {
    isMedios: {
      type: Boolean,
      default: false
    },
    isListFiles: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOpen: false,
      hasError: false,
      errorMessage: '',
      tabsClasses: {
        default: 'cursor-pointer border-transparent text-geb-green-secondary hover:border-gray-300 whitespace-nowrap pb-1 px-1 md:px-0 md:pb-4 lg:pt-0.75 border-b md:border-b-2 font-normal text-base md:text-2xl 2md:text-3xl lg:text-4xl-alt lg:leading-tighter',
        current: 'cursor-pointer border-white text-white whitespace-nowrap pb-1 px-1 md:px-0 border-b md:border-b-2 font-semibold text-base md:text-2xl 2md:text-3xl lg:text-4xl-2alt lg:leading-tighter'
      },
      tabsClassesMedios: {
        default: 'cursor-pointer border-transparent text-geb-green-secondary hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-normal text-2xl 2md:text-3xl lg:text-4xl',
        current: 'cursor-pointer border-white text-white whitespace-nowrap pb-4 px-1 border-b-2 font-semibold text-2xl 2md:text-3xl lg:text-4xl'
      },
      urlElement: null,
      isAudio: false,
      isVideo: false,
      isVideoEmbed: false,
      isDocument: false,
      width: 500,
      height: 500
    }
  },
  methods: {
    toggleOpen() {
      if (this.isOpen) this.resetData();
      this.isOpen = !this.isOpen;
      document.querySelector('body').classList.toggle('overflow-hidden');
    },
    resetData() {
      this.hasError = false;
      this.errorMessage = '';
      this.urlElement = null;
      this.isAudio = false;
      this.isVideo = false;
      this.isVideoEmbed = false;
      this.isDocument = false;
      this.width = 500;
      this.height = 500;
    },
    getTabItems() {
      const items = this.$refs.tabs.querySelectorAll('[data-events]');
      return items;
    },
    setDefaultTab() {
      const items = this.getTabItems();
      this.setCurrentTab(items[0]);
    },
    setCurrentTab(item) {
      const tabItems = this.getTabItems();
      const classes = this.isMedios ? this.tabsClassesMedios : this.tabsClasses;

      tabItems.forEach(element => {
        element.classList = classes.default;
        element.setAttribute('aria-current', undefined);
        const events = this.$refs.main.querySelector(`#${element.getAttribute('data-events')}`);
        if (events) {
          events.classList.add('hidden');
          events.classList.remove('flex');
        }

        if (element.getAttribute('data-events') === item.getAttribute('data-events')) {
          element.classList = classes.current;
          element.setAttribute('aria-current', 'page')
          const currentEvents = this.$refs.main.querySelector(`#${element.getAttribute('data-events')}`);
          if (currentEvents) {
            events.classList.remove('hidden');
            currentEvents.classList.add('flex');
          }
        }
      });
    },
    handlerClicked(e) {
      this.setCurrentTab(e.target);
    },
    handlerAudioClicked(e, url, w, h) {
      this.isAudio = true;
      this.setConfig(url, w, h);
    },
    handlerWebcastClicked(e, url, w, h) {
      this.isVideo = e.target.dataset['video'] === 'mp4';
      this.isVideoEmbed = e.target.dataset['video'] === undefined;
      this.setConfig(url, w, h);
    },
    handlerDocClicked(e, url, w, h) {
      this.isDocument = true;
      this.setConfig(url, w, h);
    },
    setConfig(url, w, h) {
      if (w) this.width = w;
      if (h) this.height = h;

      this.urlElement = url;
      if (!url || url === '') {
        this.hasError = true;
        this.errorMessage = 'Se ha proporcionado una URL vacía o invalida.';
      }
      this.toggleOpen();
    },
    handlerLoadVideoEmbed() {
      let error = false;
      const { videoIframe } = this.$refs;

      try {
        const videoIframeDoc = videoIframe.contentDocument || videoIframe.contentWindow.document;
        if (!videoIframeDoc.body.innerHTML) {
          error = true;
          this.errorMessage = 'No se ha podido cargar el contenido, la URL no es valida.';
        }
      } catch (ex) {
        if (ex.name.toLowerCase() !== 'securityerror') {
          error = true;
          this.errorMessage = 'Ocurrio un error desconocido al cargar el video.';
        }
      }

      this.hasError = error;
    },
    handlerError(e) {
      this.hasError = true;
      this.errorMessage = this.getErrorMedia(e.target.error);
    },
    getErrorMedia(error) {
      switch (error.code) {
        case error.MEDIA_ERR_ABORTED:
          return 'You aborted the video playback.';
        case error.MEDIA_ERR_NETWORK:
          return 'A network error caused the audio download to fail.';
        case error.MEDIA_ERR_DECODE:
          return 'The audio playback was aborted due to a corruption problem or because the video used features your browser did not support.';
        case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          return 'The video/audio not be loaded, either because the server or network failed or because the format is not supported.';
        default:
          return 'An unknown error occurred.';
      }      
    }
  },
  mounted () {
    if (!this.isListFiles) this.setDefaultTab();
    window.addEventListener("keyup", (evt) => {
      if (this.isOpen && (evt.code.toLowerCase() === 'escape' || (evt.keyCode === 27))) this.toggleOpen();
    },false);
  },
};
</script>