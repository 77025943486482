import axios from 'axios';

export default {
  state: {
    megamenus: []
  },
  mutations: {
    getMegamenusSuccess(state, value) {
      state.megamenus = value;
    }
  },
  actions: {
    async getMegamenusRequest({ commit }, endpoint) {
      await axios.get(endpoint)
        .then(res => {
          if ([200].includes(res.status)) {
            commit('getMegamenusSuccess', res.data);
          } else {
            return Promise.reject(new Error('Unknow error'));
          }
        })
        .catch(err => {
          return Promise.reject(new Error(err.message));
        });
    }
  }
}