<template>
  <div class="ezrichtext-field">
    <div class="accordion" :id="accordionId" ref="accordion">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VAccordion",
  props: {
    openFirstItem: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    accordionId() {
      return `accordion-${this.uid}`;
    }
  },
  mounted () {
    if ( this.openFirstItem ) {
      const accordions = this.$refs.accordion.querySelectorAll('.accordion-item');
      const firstAccordion = accordions[0];
      const buttonAccordion = firstAccordion.querySelector('.accordion-button');
      buttonAccordion.classList.remove('collapsed');
      buttonAccordion.setAttribute('aria-expanded', true);

      const collapseAccordion = firstAccordion.querySelector('.accordion-collapse');
      collapseAccordion.classList.add('show');
    }
  },
}
</script>